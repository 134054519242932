import React, { useEffect, useContext, useState } from 'react';
import SwenSection from './SwenSection';
import WorkspaceMenu from '../../components/Sidebar/WorkspaceMenu';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../contextApi/AccountContext';
import { WorkspaceProvider } from '../../contextApi/WorkspaceContext';
import PopupModal from '../../components/PopupModal';

export default function Workspace() {
  const navigate = useNavigate();
  const { getSession, sessionJWT, userStatus } = useContext(AccountContext);
  const [popupModalStatus, setPopupModalStatus] = useState(false);

  const openAuthenticatePopup = () => {
    const goToSignIn = () => navigate('/signin');
    PopupModal.closePopupModal = () => goToSignIn();
    PopupModal.popupMessage = 'Please sign in first';
    PopupModal.handleConfirmAction = () => goToSignIn();
    setPopupModalStatus(true);
  };

  useEffect(() => {
    if (!sessionJWT) {
      getSession();
      if (userStatus.userNotSignedIn) {
        openAuthenticatePopup();
      }
    } else return;
  }, [sessionJWT]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <WorkspaceProvider>
        <Container>
          <WorkspaceMenu />
          <Content>
            <SwenSection />
          </Content>
        </Container>
      </WorkspaceProvider>
      <PopupModal
        popupModalStatus={popupModalStatus}
        closePopupModal={PopupModal.closePopupModal}
        popupMessage={PopupModal.popupMessage}
        handleConfirmAction={PopupModal.handleConfirmAction}
      />
    </>
  );
}

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const Content = styled.div`
  flex: 1;
  margin-left: 200px;
`;
