import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Snow theme CSS
import 'react-quill/dist/quill.bubble.css'; // Import Bubble theme CSS
import MediaImages from './components/MediaImages';
import './Draft.css';
import MediaInfo from './components/MediaInfo';
import { AccountContext } from '../../../contextApi/AccountContext';
import { APIs } from '../../../config';
import { Button } from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import PopupModal from '../../../components/PopupModal';
import InputBoxAi from './components/InputBoxAi';

// import QuillToolbar, { modules, formats } from './EditorToolbar';

export default function Draft() {
  const [article, setArticle] = useState({});
  const { sessionJWT } = useContext(AccountContext);
  const [headline, setHeadline] = useState('');
  const [subheadline, setSubheadline] = useState([]);
  const [description, setDescription] = useState('');
  const [body, setBody] = useState('');
  const [keywords, setKeywords] = useState([]);
  const [category, setCategory] = useState('');
  const { workspaceId, articleId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const quillRef = useRef(null);
  const [thumbnail, setThumbnail] = useState(null);
  const navigate = useNavigate();
  const [popupModalStatus, setPopupModalStatus] = useState(false);
  const closePopupModal = () => {
    setPopupModalStatus(false);
  };

  const openCautionPopup = ({ message, confirmOption, buttonName }) => {
    const confirmAction = () => {
      if (confirmOption === 'stay') {
        return () => {
          setPopupModalStatus(false);
        };
      } else if (confirmOption === 'refresh') {
        return () => {
          window.location.reload(true);
        };
      } else if (confirmOption === 'goToHome') {
        return () => {
          navigate('/');
        };
      } else {
        return confirmOption;
      }
    };
    PopupModal.closePopupModal = () => {
      setPopupModalStatus(false);
    };
    PopupModal.popupMessage = message || 'Sorry, something went wrong.';
    PopupModal.confirmBtnName = buttonName || 'Confirm';
    PopupModal.handleConfirmAction = confirmAction();

    setPopupModalStatus(true);
  };

  const openErrorPopup = (err) => {
    PopupModal.popupMessage = err;
    PopupModal.confirmBtnName = 'Close';
    PopupModal.handleConfirmAction = () => {
      closePopupModal();
    };
  };

  useEffect(() => {
    if (sessionJWT && workspaceId) {
      setIsLoading(true);
      const fetchData = async () => {
        const request = await fetch(
          `${APIs('articles', workspaceId)}/draft/${articleId}`,
          {
            headers: { Authorization: `Bearer ${sessionJWT}` },
          }
        );
        const data = await request.json();
        if (request.ok) {
          if (data.keywords) {
            let keywords_str = data.keywords;
            keywords_str = keywords_str.replace(/[{}]/g, '');
            keywords_str = keywords_str.replace(/["]/g, '');
            const keywords_lst = keywords_str
              .split(',')
              .map((item) => item.trim());
            setKeywords(keywords_lst);
          }
          let subheadline_str = data.subheadline;
          subheadline_str = subheadline_str.replace(/[{}]/g, '');
          subheadline_str = subheadline_str.replace(/["]/g, '');
          setSubheadline(`<h2>${subheadline_str}</h2>`);
          setArticle(data);
          setHeadline(`<h1>${data.headline}</h1>`);
          setBody(data.body);
          setCategory(data.category);
          setDescription(data.description);
          setThumbnail(data.thumbnail_url);
        } else return;
      };
      fetchData();
      setIsLoading(false);
    }
  }, [sessionJWT, workspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   const quill = quillRef.current.getEditor();
  //   const handleDrop = (e) => {
  //     e.preventDefault();
  //     const imageUrl = e.dataTransfer.getData('text/plain');
  //     const caption = e.dataTransfer.getData('caption');
  //     const range = quill.getSelection();
  //     if (range) {
  //       const figureHtml = `<figure>
  //                             <img data-src="${imageUrl}" alt="${caption}" />
  //                             <figcaption class="figcaption">${caption}</figcaption>
  //                           </figure>`;
  //       quill.clipboard.dangerouslyPasteHTML(range.index, figureHtml);
  //     }
  //   };

  //   quill.root.addEventListener('drop', handleDrop);

  //   return () => {
  //     quill.root.removeEventListener('drop', handleDrop);
  //   };
  // }, []);

  const handleSave = async () => {
    const stripHTMLTags = (str) => str.replace(/<\/?[^>]+(>|$)/g, '');

    const payload = {
      headline: stripHTMLTags(headline),
      subheadline: stripHTMLTags(subheadline),
      description: stripHTMLTags(description),
      body: body,
      keywords: keywords.join(', '),
      category: category,
      thumbnail_url: thumbnail,
    };
    setIsLoading(true);
    try {
      const request = await fetch(
        `${APIs('articles', workspaceId)}/draft/${articleId}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionJWT}`,
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await request.json();
      if (request.ok) {
        window.location.reload();
      } else {
        openErrorPopup(`${Object.keys(data)[0]} is required`);
        setPopupModalStatus(true);
      }
    } catch (error) {
      console.error('Error saving article:', error);
    }
    setIsLoading(false);
  };

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 4] }],
        ['bold', 'underline', 'italic'],
        ['link', 'image'],
      ],
    },
  };

  return (
    <>
      <Wrapper>
        <GridWrapper>
          <QuillWrapper>
            <TitleSection>
              <MenuWrapper>제목</MenuWrapper>
              <ReactQuill
                theme='bubble'
                value={headline}
                onChange={setHeadline}
                ref={quillRef}
              />
            </TitleSection>
            <DescriptionSection>
              <MenuWrapper>부제목</MenuWrapper>
              <ReactQuill
                theme='bubble'
                value={subheadline}
                onChange={setSubheadline}
              />
            </DescriptionSection>
            <DescriptionSection>
              <MenuWrapper>리드문</MenuWrapper>
              <ReactQuill
                theme='bubble'
                value={description}
                onChange={setDescription}
              />
            </DescriptionSection>
            <BodySection>
              <MenuWrapper>본문</MenuWrapper>
              <ReactQuill
                theme='snow'
                value={body}
                onChange={setBody}
                placeholder={'Write something awesome...'}
                modules={modules}
              />
            </BodySection>
          </QuillWrapper>
          <InfoWrapper>
            <InputBoxAi
              sessionJWT={sessionJWT}
              workspaceId={workspaceId}
              openCautionPopup={openCautionPopup}
              body={body}
              sourceUrl={article.source_url || null}
              keywords={keywords}
              setKeywords={setKeywords}
              category={category}
              setCategory={setCategory}
              thumbnail={thumbnail}
              setThumbnail={setThumbnail}
              images={article?.images}
            />
            <ButtonWrapper>
              <CustomButton
                key='SaveArticle'
                variant='contained'
                disableRipple
                onClick={handleSave}
              >
                기사 저장하기
              </CustomButton>
            </ButtonWrapper>
            {/* <MediaInfo
              sourceUrl={article.source_url || null}
              keywords={keywords}
              setKeywords={setKeywords}
              category={category}
              setCategory={setCategory}
              thumbnail={thumbnail}
            />
            <MediaImages images={article?.images} setThumbnail={setThumbnail} />
            <ButtonWrapper>
              <CustomButton
                key='SaveArticle'
                variant='contained'
                disableRipple
                onClick={handleSave}
              >
                저장
              </CustomButton>
            </ButtonWrapper> */}
          </InfoWrapper>
        </GridWrapper>
      </Wrapper>
      <PopupModal
        popupModalStatus={popupModalStatus}
        closePopupModal={closePopupModal}
        popupMessage={PopupModal.popupMessage}
        confirmBtnName={PopupModal.confirmBtnName}
        handleConfirmAction={PopupModal.handleConfirmAction}
      />
    </>
  );
}

const Wrapper = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: 1300px;
  padding: 0 32px;
  padding-bottom: 32px;
  width: 100%;
  height: auto;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
`;

const QuillWrapper = styled.div`
  grid-column: span 7;
  border-radius: 10px;
  border: 1px solid rgba(0, 48, 126, 0.6);
  box-shadow: 0px 4px 6px rgba(0, 48, 126, 0.4);
  border-color: '#00307E';
  padding: 20px;
  margin-top: 40px;
`;

const InfoWrapper = styled.div`
  grid-column: span 5;
  height: 100vh;
`;

const BoxContainer = styled.div`
  margin-top: 40px;
  height: 85vh;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid rgba(0, 48, 126, 0.6);
  box-shadow: 0px 4px 6px rgba(0, 48, 126, 0.4);
  border-color: '#00307E';

  /* Hide scrollbar for Webkit browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

const MenuWrapper = styled.div`
  color: #00307e;
  font-family: 'Roboto';
  font-size: 15px;
  font-weight: 500;
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 18px;
`;

const DescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 18px;
`;

const BodySection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 18px;
`;

const SectionHeader = styled.div`
  font-family: 'Poppins';
  font-size: 14px;
  color: gray;
  margin-bottom: 10px;
`;

const CustomButton = MuiStyled(Button)({
  height: '60px',
  width: '100%',
  boxShadow: '0px 4px 6px rgba(68, 80, 95, 0.6);',
  textTransform: 'none',
  fontSize: 16,
  fontWeight: 500,
  padding: '6px 12px',
  borderRadius: '4x',
  backgroundColor: '#44505F',
  lineHeight: 1.5,
  fontFamily: 'Arial',
  outline: 'none',
  color: 'white',
  '&:hover': {
    backgroundColor: '#242B35',
    boxShadow: 'none',
  },
  cursor: 'pointer',
});

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 20px;
`;
