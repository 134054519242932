import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as AiIconBase } from '../../../../assets/icons/AiIcon.svg';
import { ReactComponent as CopyIcon } from '../../../../assets/icons/document-duplicate.svg';
import { APIs } from '../../../../config';
import MediaInfo from './MediaInfo';
import MediaImages from './MediaImages';

export default function InputBoxAi(props) {
  const {
    sessionJWT,
    workspaceId,
    openCautionPopup,
    body,
    sourceUrl,
    keywords,
    setKeywords,
    category,
    setCategory,
    thumbnail,
    setThumbnail,
    images,
  } = props;
  const [isAiRequest, setIsAiRequest] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [outputText, setOutputText] = useState('');
  const [inputBody, setInputBody] = useState('');

  const handleSwitch = (isAiSelected) => {
    setIsAiRequest(isAiSelected);
  };

  const handleCopy = () => {
    const textToCopy = document.getElementById('outputBox').innerText;
    navigator.clipboard.writeText(textToCopy).then(() => {
      alert('Text copied to clipboard!');
    });
  };

  const handleCreate = async () => {
    const inputText = document.getElementById('aiRequestBox').value.trim();
    console.log(inputText);

    if (inputText.length === 0) {
      openCautionPopup({
        message: '프롬프트를 입력하세요',
        confirmOption: 'stay',
      });
      return;
    }

    setIsLoading(true);
    setOutputText(''); // Clear the output before the request
    try {
      const request = await fetch(`${APIs('prompt', workspaceId)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionJWT}`,
        },
        body: JSON.stringify({ body: inputBody, input: inputText }),
      });
      const data = await request.json();
      if (request.ok) {
        setIsLoading(false);
        setOutputText(data.data);
      } else {
        setIsLoading(false);
        setOutputText('An error occurred. Please try again.');
      }
    } catch (error) {
      setIsLoading(false);
      setOutputText('An error occurred. Please try again.');
      console.error('Error saving article:', error);
    }
  };

  useEffect(() => {
    if (body) {
      setInputBody(body);
    }
  }, [body]);

  return (
    <BoxContainer>
      <SwitchWrapper>
        <SwitchArea onClick={() => handleSwitch(true)}>
          {/* <StyledAiIcon selected={isAiRequest} /> */}
          <SwitchText selected={isAiRequest}>추가 AI 요청</SwitchText>
          <SwitchBar selected={isAiRequest} />
        </SwitchArea>
        <SwitchArea onClick={() => handleSwitch(false)}>
          <SwitchText selected={!isAiRequest}>기타 사항</SwitchText>
          <SwitchBar selected={!isAiRequest} />
        </SwitchArea>
      </SwitchWrapper>
      <ContentArea>
        {isAiRequest ? (
          <div>
            <BoxTitle>프롬프트 입력</BoxTitle>
            <AiRequestBox id='aiRequestBox' />
            <ButtonWrapper>
              <ExecuteButton onClick={handleCreate}>생성하기</ExecuteButton>
            </ButtonWrapper>
            <BoxTitle>AI 생성 결과</BoxTitle>
            <OutputBox id='outputBox'>
              {isLoading ? (
                <Loader>생성중...</Loader>
              ) : (
                outputText || '결과가 여기에 표시됩니다.'
              )}
            </OutputBox>
            <ButtonWrapper>
              <CopyButton onClick={handleCopy}>
                복사하기 <CopyIcon />
              </CopyButton>
            </ButtonWrapper>
          </div>
        ) : (
          <div>
            <MediaInfo
              sourceUrl={sourceUrl}
              keywords={keywords}
              setKeywords={setKeywords}
              category={category}
              setCategory={setCategory}
              thumbnail={thumbnail}
            />
            <MediaImages images={images} setThumbnail={setThumbnail} />
          </div>
        )}
      </ContentArea>
    </BoxContainer>
  );
}

const BoxContainer = styled.div`
  margin-top: 40px;
  height: 85vh;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid rgba(0, 48, 126, 0.6);
  box-shadow: 0px 4px 6px rgba(0, 48, 126, 0.4);
`;

const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 48px;
  margin-top: 32px;
`;

const SwitchArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  gap: 10px;
`;

const StyledAiIcon = styled(AiIconBase)`
  fill: ${(props) => (props.selected ? '#00307E' : '#626060')};
`;

const SwitchText = styled.div`
  color: ${(props) => (props.selected ? '#00307E' : '#626060')};
  font-size: 15px;
  font-weight: ${(props) => (props.selected ? '700' : '500')};
  font-family: 'Roboto';
`;

const SwitchBar = styled.div`
  width: 160px;
  height: 4px;
  background-color: ${(props) => (props.selected ? '#00307E' : '#E0E0E0')};
`;

const ContentArea = styled.div`
  margin-top: 20px;
  padding: 16px 32px 16px 32px;
  flex-grow: 1;
  overflow-y: auto;
`;

const BoxTitle = styled.div`
  color: #696969;
  font-size: 15px;
  font-family: 'Roboto';
  font-weight: 700;
  padding: 4px;
`;
const AiRequestBox = styled.textarea`
  border: 2px solid rgba(105, 105, 105, 0.5);
  border-radius: 8px;
  padding: 16px;
  height: 160px;
  width: 100%;
  overflow-y: scroll;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #333; /* Optional: Set a default font color */
  line-height: 1.5; /* Optional: Better readability */
  resize: none; /* Disable resizing */
  white-space: pre-wrap; /* Preserve spaces and line breaks */
  word-wrap: break-word; /* Wrap long words to avoid overflow */
  text-align: start; /* Start text from the top-left corner */
  box-sizing: border-box; /* Ensure padding is included in height/width */
`;

const OutputBox = styled.div`
  border: 2px solid rgba(105, 105, 105, 0.5);
  border-radius: 8px;
  padding: 16px;
  min-height: 280px;
  max-height: 380px;
  width: 100%;
  overflow-y: scroll;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #333;
  box-sizing: border-box; /* Ensure padding is included in dimensions */
  white-space: pre-wrap; /* Wrap text and preserve line breaks */
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px; /* Spacing between OutputBox and button */
`;

const CopyButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px; /* Space between icon and text */
  padding: 4px;
  background: none;
  color: #0b8ffc;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;
const ExecuteButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px; /* Space between icon and text */
  padding: 8px;
  background-color: #696969;
  border-radius: 4px;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */

  &:hover {
    background-color: black; /* Change background color on hover */
  }
`;
const Loader = styled.div`
  color: #696969;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
`;
const OtherBox = styled.div`
  border: 1px solid rgba(0, 48, 126, 0.6);
  border-radius: 8px;
  padding: 16px;
`;
